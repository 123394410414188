import numeral from "numeral";


class FormatNumber {
  formatFloat(number) {
    return numeral(number).format("0,0.00");
  }
  formatInt(number) {
    return numeral(number).format("0,0");
  }
}

export default new FormatNumber();
