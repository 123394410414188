<template>
  <div class="mx-3">
    <div class="row">
      <v-card class="col-4 mr-2 rounded-xl">
        <v-card-text class="d-flex">
          <div class="mr-3">
            <v-avatar size="55px">
              <img
                v-if="members.user_profile"
                :src="members.user_profile"
                @error="imageError"
                style="object-fit:cover"
              />
              <v-img v-else :src="require('@/assets/images/avatars/1.png')"></v-img>
            </v-avatar>
          </div>

          <div class="my-1">
            <h3>{{ members.fname }} {{ members.lname }}</h3>

            <v-row class="pt-1" no-gutters>
              <span>
                <v-card
                  class="border-left-tier px-5 py-1"
                  elevation="1"
                  :style="`background-color: ${members.userTier.color ? members.userTier.color : '#AAAAA'}`"
                >
                  <v-icon style="color:white" size="20">mdi-crown </v-icon>
                </v-card>
              </span>
              <span>
                <v-card class="border-right-tier px-5 py-1" elevation="1">
                  <div :style="`color: ${members.userTier.color ? members.userTier.color : '#AAAAA'}`">
                    {{ members.userTier.name_th }}
                  </div>
                </v-card>
              </span>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
      <v-card class="col rounded-xl">
        <v-card-text class="d-flex justify-space-between">
          <div>
            <v-avatar class="mr-2 bg-icons" size="55px">
              <v-icon large>{{ icons.mdiCakeVariant }}</v-icon>
            </v-avatar>
            <span>{{ members.birthday ? formatBirthDate(members.birthday) : 'ไม่ระบุ' }}</span>
          </div>
          <div>
            <v-avatar class="mr-2 bg-icons" size="55px"> AGE </v-avatar>
            <span> {{ members.birthday ? 'อายุ' + _calculateAge(members.birthday) + 'ปี' : 'ไม่ระบุ' }} </span>
          </div>
          <div>
            <v-avatar class="mr-2 bg-icons" size="55px">
              <v-icon large>{{ icons.mdiCellphoneIphone }}</v-icon>
            </v-avatar>
            {{ members.phone }}
          </div>
          <div>
            <v-avatar class="mr-2 bg-icons" size="55px">
              <v-icon large>{{ icons.mdiEmail }}</v-icon>
            </v-avatar>
            {{ members.email == '' ? '-' : members.email }}
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { mdiCakeVariant, mdiCellphoneIphone, mdiEmail } from '@mdi/js'
import moment from 'moment'
export default {
  data() {
    return {
      members: '',
    }
  },
  setup() {
    return {
      icons: {
        mdiCakeVariant,
        mdiCellphoneIphone,
        mdiEmail,
      },
    }
  },
  methods: {
    _calculateAge(birthday) {
      // birthday is a date
      var ageDifMs = Date.now() - moment(birthday).valueOf()
      var ageDate = new Date(ageDifMs) // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970)
    },
    formatBirthDate(birthday) {
      moment.locale('th')
      return moment(birthday)
        .add(543, 'year')
        .format('LL')
    },

    imageError(event) {
      event.target.src = require('@/assets/images/avatars/1.png')
    },
  },
  mounted() {
    var id = this.$store.getters.dataOrders.data.user.user_crm_id

    this.$store.dispatch('getDataMember', id).then(res => {
      console.log(res)
      this.members = res
    })
  },
}
</script>
<style scoped>
.bg-icons {
  background-color: #ffd52f;
}
.border-left-tier {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.border-right-tier {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
</style>
