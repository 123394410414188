<template>
  <div>
    <v-row>
      <v-col cols="11" md="11">
        <v-alert color="#3C6681" dark>
          <v-row>
            <v-col cols="3">
              <v-icon color="#FFD52F">mdi-card-account-details-outline</v-icon>
              1509901467571
            </v-col>
            <v-col cols="3"><v-icon color="#FFD52F">mdi-account-circle-outline</v-icon> นางสาวโรจนา กุลสตรี</v-col>
            <v-col cols="6"
              ><v-icon color="#FFD52F">mdi-book-outline </v-icon> 93/5 ซอย 12 หมู่ 2 ตำบลหนองจ๊อม อำเภอสันทราย
              จังหวัดเชียงใหม่ 50210</v-col
            >
          </v-row>
        </v-alert>
      </v-col>
      <v-col cols="1" md="1" class="my-3">
        <a style="text-decoration: none; color: #d2a802" @click="modalAddress">
          <v-icon color="#D2A802" large>mdi-credit-card-marker-outline</v-icon>
          เพิ่ม
        </a>
        <a style="text-decoration: none; color: #d2a802" @click="modalEditAddress">
          <v-icon color="#D2A802" large>mdi-pencil-box</v-icon>
          แก้ไข
        </a>
      </v-col>
    </v-row>

    <!-- dialog address user -->
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          {{ edit ? 'แก้ไขที่อยู' : 'เพิ่มที่อยู่' }}
        </v-card-title>
        <v-card-text>
          <v-form ref="formEdit" v-model="valid" lazy-validation class="multi-col-validation">
            <v-row id="form-address">
              <v-col cols="12" md="6">
                <v-text-field
                  type="text"
                  v-model="full_name"
                  :rules="rules"
                  required
                  outlined
                  dense
                  placeholder="ชื่อร้าน"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  type="number"
                  v-model="tax_number"
                  :rules="idCardRules"
                  required
                  outlined
                  dense
                  placeholder="หมายเลขผู้เสียภาษี"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  type="number"
                  v-model="phone"
                  :rules="rulesPhone"
                  required
                  outlined
                  dense
                  placeholder="เบอร์โทร"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="address"
                  :rules="rules"
                  outlined
                  dense
                  placeholder="ที่อยู่"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <ThailandAutoComplete
                  v-model="zip_code"
                  type="zipcode"
                  @select="select"
                  color="#35495e"
                  placeholder="รหัสไปรษณีย์"
                />
              </v-col>

              <v-col cols="12" md="6">
                <ThailandAutoComplete
                  v-model="district"
                  type="district"
                  @select="select"
                  color="#35495e"
                  placeholder="ตำบล"
                  class="test"
                />
              </v-col>

              <v-col cols="12" md="6">
                <ThailandAutoComplete
                  v-model="amphure"
                  type="amphoe"
                  @select="select"
                  color="#35495e"
                  placeholder="อำเภอ"
                />
              </v-col>

              <v-col cols="12" md="6">
                <ThailandAutoComplete
                  v-model="province"
                  type="province"
                  @select="select"
                  color="#35495e"
                  placeholder="จังหวัด"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            ยกเลิก
          </v-btn>
          <v-btn color="primary" :disabled="!valid || checkAddress" @click="handleSubmit">
            บันทึก
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ThailandAutoComplete from 'vue-thailand-address-autocomplete'
export default {
  components: {
    ThailandAutoComplete,
  },
  data() {
    return {
      rules: [v => !!v || 'กรุณากรอกข้อมูล'],
      rulesPhone: [v => !!v || 'กรุณากรอกข้อมูล', v => v.length <= 10 || 'เบอร์โทร 9-10 ตัวอักษร'],
      idCardRules: [v => !!v || 'กรุณากรอกข้อมูล', v => v.length == 13 || 'หมายเลขผู้เสียภาษี 13 ตัว'],

      valid: false,
      full_name: '',
      tax_number: '',
      phone: '',
      address: '',
      amphure: '',
      district: '',
      province: '',
      zip_code: '',
      detailsAddress: [],
      dialog: false,
    }
  },
  methods: {
    modalAddress() {
      this.edit = false
      this.dialog = !this.dialog
    },
     modalEditAddress() {
      this.edit = true
      this.dialog = !this.dialog
    },
    select(address) {
      this.district = address.district
      this.amphure = address.amphoe
      this.province = address.province
      this.zip_code = address.zipcode
    },
    handleSubmit() {
      var data = {
        full_name: this.full_name,
        tax_number: this.tax_number,
        phone: this.phone,
        address: this.address,
        amphure: this.amphure,
        district: this.district,
        province: this.province,
        zip_code: this.zip_code,
      }
      console.log(data)
    },
  },
  computed: {
    checkAddress() {
      if (this.zip_code == '' || this.district == '' || this.amphure == '' || this.province == '') {
        return true
      } else {
        return false
      }
    },
  },
}
</script>

<style>
*[data-v-1f53c317] {
  box-sizing: border-box;
  font-family: 'Noto Sans Thai', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  box-shadow: inset 0 0px 0px hsl(0deg 0% 0% / 0%) !important;
}
.vth-addr-input[data-v-1f53c317] {
  float: left;
  width: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  padding: calc(0.595em - 0.5px) 0.75rem;
  line-height: 1.7;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
  border: solid 1px #e2e2e2 !important;
}
.vth-addr-input-size-default[data-v-1f53c317] {
  font-size: 14px;
}
#form-address ::placeholder {
  color: rgb(151, 151, 151);
}
</style>
